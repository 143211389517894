import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { FaClipboard } from 'react-icons/fa';
import { 
  numbers, 
  upperCaseLetters, 
  lowerCaseLetters, 
  specialCharacters 
} from './helpers/characters';
import { COPY_SUCCESS } from './helpers/message';

import './styles/App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [password, setPassword] = useState('');
  const [passwordLength, setPasswordLength] = useState(20);
  const [includeUppercase, setIncludeUppercase] = useState(true);
  const [includeLowercase, setIncludeLowercase] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSymbols, setIncludeSymbols] = useState(true);

  const handleGeneratePassword = (e) => {
    if(!includeUppercase && !includeLowercase && !includeNumbers && !includeNumbers && !includeSymbols) {
      notify('Você precisa selecionar pelo menos uma opção', true);
    }

    let characterList = '';

    if(includeLowercase) {
      characterList = characterList + lowerCaseLetters;
    }
    if(includeUppercase) {
      characterList = characterList + upperCaseLetters;
    }
    if(includeNumbers) {
      characterList = characterList + numbers;
    }
    if(includeSymbols) {
      characterList = characterList + specialCharacters;
    }
    setPassword(createPassword(characterList));
  }

  const handleCopyPassword = (e) => {
    if(password === '') {
      notify('Nada para copiar', true);
    } else {
      copyToClipboard();
      notify(COPY_SUCCESS);
    }
  }

  const copyToClipboard = () => {
    const newTextArea = document.createElement('textarea')
    newTextArea.innerText = password;
    document.body.appendChild(newTextArea);
    newTextArea.select();
    document.execCommand('copy');
    newTextArea.remove();
  }

  const createPassword = (characterList) => {
    let password = '';
    const characterListLength = characterList.length;

    for(let i = 0; i < passwordLength; i++) {
      const characterIndex = Math.round(Math.random() * characterListLength);
      password = password + characterList.charAt(characterIndex);
    }

    return password;
  }

  const notify = (message, hasError = false) => {
    if(hasError) {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <div className="App">
      <div className="container">
        <div className="generator">
          <h2 className="generator__header">
            Gerador de Senhas
          </h2>
          <div className="generator__password">
            <h3>{password}</h3>
            <button 
              className="copy__btn"
              onClick={handleCopyPassword}
              title='Copiar senha'
            >
              <FaClipboard
                className='copy__btn'
              />
            </button>
          </div>

          <div className="form-group">
            <label htmlFor="password-strength">Tamanho da senha</label>
            <input 
              type="number" 
              id="password-strength" 
              name="password-strength" 
              max="20" 
              min="10"
              defaultValue={passwordLength}
              onChange={(e) => setPasswordLength(e.target.value)}
              className="password-strength__input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="uppercase-letters">Incluir letras em caixa alta</label>
            <input 
              type="checkbox" 
              id="uppercase-letters" 
              name="uppercase-letters"
              checked={includeUppercase}
              onChange={(e) => setIncludeUppercase(e.target.checked)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="lowercase-letters">Incluir letras em caixa baixa</label>
            <input 
              type="checkbox" 
              id="lowercase-letters" 
              name="lowercase-letters"
              checked={includeLowercase}
              onChange={(e) => setIncludeLowercase(e.target.checked)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="include-numbers">Incluir números</label>
            <input 
              type="checkbox" 
              id="include-numbers" 
              name="include-numbers"
              checked={includeNumbers}
              onChange={(e) => setIncludeNumbers(e.target.checked)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="include-symbols">Incluir símbolos</label>
            <input 
              type="checkbox" 
              id="include-symbols" 
              name="include-symbols" 
              checked={includeSymbols}
              onChange={(e) => setIncludeSymbols(e.target.checked)}
            />
          </div>

          <button 
            className="generator__btn"
            onClick={handleGeneratePassword}
          >
            Gerar minha Senha
          </button>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
            {/* Same as */}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default App;
